<template>
  <div class="about">
    <Header/>
    <About/>
    <Footer/>
  </div>
</template>

<script>

import About from "@/components/About";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  components: {Footer, Header, About},
}
</script>

<style lang="scss" scoped>
.about{
  background-color: #0c1015;
}
</style>
Selected Option: