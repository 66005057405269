<template>
  <div class="xstone">
    <Header/>
    <XstoneMain/>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import XstoneMain from "@/components/XstoneMain";
import Footer from "@/components/Footer";

export default {
  components: {Footer, XstoneMain, Header}
}
</script>

<style scoped>
.xstone {
  background-color: #0c1015;
}
</style>