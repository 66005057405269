import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'bootstrap';
import VueKinesis from "vue-kinesis";
const app = createApp(App);

app.use(VueKinesis);
createApp(App).use(router).mount('#app')
