<template>
  <div>
    <Header/>
    <ESBMain/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import ESBMain from "@/components/ESBMain";

export default {
  components: { ESBMain, Header}
}
</script>

<style scoped>

</style>