<template>
  <canvas ref="canvas"></canvas>
</template>

<script>
import WebGLFluid from 'webgl-fluid'

export default {
  mounted() {
    WebGLFluid(this.$refs.canvas, {
      IMMEDIATE: false,
      TRIGGER: 'hover',
      SIM_RESOLUTION: 128,
      DYE_RESOLUTION: 1024,
      CAPTURE_RESOLUTION: 512,
      DENSITY_DISSIPATION: 4,
      VELOCITY_DISSIPATION: 0.5,
      PRESSURE: 0.1,
      PRESSURE_ITERATIONS: 20,
      CURL: 32,
      SPLAT_RADIUS: 0.1,
      SPLAT_FORCE: 6000,
      SPLAT_COUNT: parseInt(Math.random() * 20) + 5, // Initial splats count (when IMMEDIATE is set to true)
      SHADING: true,
      COLORFUL: true,
      COLOR_UPDATE_SPEED: 10,
      PAUSED: false,
      BACK_COLOR: { r: 0, g: 0, b: 0 },
      TRANSPARENT: true,
      BLOOM: false,
      BLOOM_ITERATIONS: 8,
      BLOOM_RESOLUTION: 256,
      BLOOM_INTENSITY: 0.8,
      BLOOM_THRESHOLD: 0.6,
      BLOOM_SOFT_KNEE: 0.7,
      SUNRAYS: true,
      SUNRAYS_RESOLUTION: 64,
      SUNRAYS_WEIGHT: 0.6,
    })
  }
}
</script>

<style lang="scss" scoped>
canvas {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
</style>