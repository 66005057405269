<template>
  <header class="navbar navbar-expand-lg fixed-top" :class="{ 'back-animate': isScrolled }">
    <div class="container">
      <router-link class="navbar-brand pe-sm-3" to="/">
        <img :src="require(`@/assets/images/logo.png`)" alt="" width="50">
      </router-link>
      <button class="navbar-toggler ms-sm-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <nav class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav navbar-nav-scroll me-auto" style="--ar-scroll-height: 520px;">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="javascript:void(0)" data-bs-toggle="dropdown"
               aria-expanded="false">محصولات</a>
            <div class="dropdown-menu overflow-hidden p-0">
              <div class="d-lg-flex">
                <div class="mega-dropdown-column pt-1 pt-lg-3 pb-lg-4">
                  <ul class="list-unstyled mb-0">
                    <li>
                      <router-link to="/xstone" class="dropdown-item">زیرساخت نرم افزاری X-stone</router-link>
                      <span
                          class="mega-dropdown-column position-absolute top-0 end-0 h-100 rounded-3 rounded-start-0 bg-col"></span>
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/tarsimSystem">ترسیم سیستم</router-link>
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/esb">گذرگاه سرویس سازمانی (ESB)</router-link>
                    </li>
                    <li>
                      <router-link class="dropdown-item" to="/tarsimMate">ترسیم میت (Tarsimate)</router-link>
                    </li>
                  </ul>
                </div>
                <div class="mega-dropdown-column position-relative border-start z-3"></div>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/xstone">X-stone</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/tarsimSystem">ترسیم سیستم</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/tarsimMate">ترسیم میت</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/esb">ESB</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/gallery">گالری تصاویر</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about">درباره ما</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contact">تماس با ما</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isScrolled: false
    };
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 10;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style lang="scss" scoped>
.back-animate {
    background-color: #191b1d;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.text-primary {
  --ar-text-opacity: 1;
  color: rgba(var(--ar-primary-rgb), var(--ar-text-opacity)) !important;
}

.navbar {
  --ar-navbar-padding-x: 0;
  --ar-navbar-padding-y: 0.75rem;
  --ar-navbar-color: var(--ar-gray-800);
  --ar-navbar-hover-color: var(--ar-primary);
  --ar-navbar-disabled-color: var(--ar-gray-600);
  --ar-navbar-active-color: var(--ar-primary);
  --ar-navbar-brand-padding-y: 0.5rem;
  --ar-navbar-brand-margin-end: 1rem;
  --ar-navbar-brand-font-size: 1.375rem;
  --ar-navbar-brand-color: var(--ar-gray-800);
  --ar-navbar-brand-hover-color: var(--ar-gray-800);
  --ar-navbar-nav-link-padding-x: 1rem;
  --ar-navbar-toggler-padding-y: 0.625rem;
  --ar-navbar-toggler-padding-x: 0.25rem;
  --ar-navbar-toggler-font-size: calc(var(--ar-body-font-size) * 1.125);
  --ar-navbar-toggler-icon-bg: initial;
  --ar-navbar-toggler-border-color: transparent;
  --ar-navbar-toggler-border-radius: 0;
  --ar-navbar-toggler-focus-width: 0;
  --ar-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  transition: background-color 0.3s ease;
}

.navbar {
  --ar-navbar-stuck-bg: var(--ar-light);
  --ar-navbar-toggler-color: var(--ar-gray-800);
  --ar-navbar-collapse-bg: var(--ar-dark);
  --ar-navbar-collapse-border-color: var(--ar-border-color);
}

.navbar-nav {
  --ar-nav-link-font-weight: 500;
  --ar-mega-dropdown-column-width: 14rem;
  padding: .75rem 1.25rem;
}

.navbar-nav {
  --ar-nav-link-padding-x: 0;
  --ar-nav-link-padding-y: 0.725rem;
  --ar-nav-link-font-weight: 500;
  --ar-nav-link-color: var(--ar-navbar-color);
  --ar-nav-link-hover-color: var(--ar-navbar-hover-color);
  --ar-nav-link-disabled-color: var(--ar-navbar-disabled-color);
  list-style: none;
}

.navbar-nav {
  --ar-nav-link-font-weight: 500;
  --ar-mega-dropdown-column-width: 14rem;
}

.dropdown-menu {
  --ar-dropdown-zindex: 1000;
  --ar-dropdown-min-width: 12rem;
  --ar-dropdown-padding-x: 0;
  --ar-dropdown-padding-y: 0.75rem;
  --ar-dropdown-spacer: 0.25rem;
  --ar-dropdown-font-size: calc(var(--ar-body-font-size) * 0.875);
  --ar-dropdown-color: var(--ar-body-color);
  --ar-dropdown-bg: #090c10;
  --ar-dropdown-border-color: #f0f3f6;
  --ar-dropdown-border-radius: var(--ar-border-radius-lg);
  --ar-dropdown-border-width: var(--ar-border-width);
  --ar-dropdown-inner-border-radius: calc(var(--ar-border-radius-lg) - var(--ar-border-width));
  --ar-dropdown-divider-bg: var(--ar-border-color);
  --ar-dropdown-divider-margin-y: 0.375rem;
  --ar-dropdown-box-shadow: 0 0.4375rem 1.25rem rgba(208, 208, 196, 0.32);
  --ar-dropdown-link-color: var(--ar-gray-800);
  --ar-dropdown-link-hover-color: var(--ar-primary);
  --ar-dropdown-link-hover-bg: transparent;
  --ar-dropdown-link-active-color: var(--ar-primary);
  --ar-dropdown-link-active-bg: transparent;
  --ar-dropdown-link-disabled-color: var(--ar-gray-600);
  --ar-dropdown-item-padding-x: 1.25rem;
  --ar-dropdown-item-padding-y: 0.375rem;
  --ar-dropdown-header-color: var(--ar-gray-900);
  --ar-dropdown-header-padding-x: 1.25rem;
  --ar-dropdown-header-padding-y: 0.75rem;
}

.navbar-toggler {
  margin: 0;
}

.navbar-collapse {
  margin-top: 1rem;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: var(--ar-border-radius-lg);
}

.navbar .navbar-collapse {
  background-color: var(--ar-navbar-collapse-bg);
  border-color: var(--ar-navbar-collapse-border-color);
}

.nav-item {
  margin-bottom: 0;
}

.dropdown-item {
  margin-bottom: .5rem;

  &:active {
    color: var(--color-1);
    text-decoration: none;
    background-color: #ffffff;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--color-2);
}

.router-link-active, .router-link-exact-active {
  color: var(--color-2);
}

.router-active {
  color: var(--color-2);
}

.nav-link {
  display: flex;
  align-items: center;
  background: none;
  border: 0;
  color: #fff !important;
}

.dropdown-toggle::after {
  width: 1.375em;
  height: 1.375em;
  margin-left: -0.25rem;
  content: "";
  background-color: currentcolor;
  border: 0 !important;
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M8.7,13.7c0.4-0.4,1.1-0.4,1.5,0l6,6l6-6c0.4-0.4,1.1-0.4,1.5,0s0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0l-6.7-6.7C8.3,14.6,8.3,14.1,8.7,13.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M8.7,13.7c0.4-0.4,1.1-0.4,1.5,0l6,6l6-6c0.4-0.4,1.1-0.4,1.5,0s0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0l-6.7-6.7C8.3,14.6,8.3,14.1,8.7,13.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.navbar .dropdown-toggle::after {
  width: 1.25em;
  height: 1.25em;
  margin-left: 0;
  margin-bottom: 4px;
}

.bg-col {
  background-image: url("@/assets/images/home/4.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: var(--color-1);
}

.navbar .navbar-nav .dropdown-menu {
  padding-top: calc(var(--ar-dropdown-padding-y) * .25);
  padding-bottom: calc(var(--ar-dropdown-padding-y) * .5);
  background: none;
  border: 0;
  border-right-color: currentcolor;
  border-right: var(--ar-dropdown-border-width, 1px) solid var(--ar-navbar-collapse-border-color);
  border-radius: 0;
  box-shadow: none !important;
}

@media only screen and (min-width: 576px) {
  .ms-sm-3 {
    margin-right: 1rem !important;
  }
}

@media only screen and (min-width: 992px) {
  .navbar-expand-lg .dropdown::before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: .375rem;
    content: "";
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--ar-navbar-nav-link-padding-x);
    padding-left: var(--ar-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-top: var(--ar-nav-link-padding-y);
    padding-bottom: var(--ar-nav-link-padding-y);
  }
  .dropdown-menu {
    position: absolute;
    z-index: var(--ar-dropdown-zindex);
    display: none;
    min-width: var(--ar-dropdown-min-width);
    padding: var(--ar-dropdown-padding-y) var(--ar-dropdown-padding-x);
    margin: 0;
    font-size: var(--ar-dropdown-font-size);
    color: var(--ar-dropdown-color);
    text-align: right;
    list-style: none;
    background-color: var(--ar-dropdown-bg);
    background-clip: padding-box;
    border: var(--ar-dropdown-border-width) solid var(--ar-dropdown-border-color);
    border-radius: var(--ar-dropdown-border-radius);
  }
  .navbar-expand-lg .dropdown:hover > .dropdown-menu {
    display: block;
    animation: dropdown-show .25s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    padding-top: var(--ar-dropdown-padding-y);
    padding-bottom: var(--ar-dropdown-padding-y);
    margin-top: var(--ar-dropdown-spacer) !important;
    background-color: var(--ar-dropdown-bg);
    border: var(--ar-dropdown-border-width) solid var(--ar-dropdown-border-color);
    border-radius: var(--ar-dropdown-border-radius);
    box-shadow: var(--ar-dropdown-box-shadow) !important;
  }
  .navbar-expand-lg .mega-dropdown-column {
    width: var(--ar-mega-dropdown-column-width);
    padding: 0 .5rem;

  }
  .navbar-expand-lg .navbar-collapse {
    margin-top: 0;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
  }
  .navbar-expand-lg .navbar-nav {
    padding: 0;
  }
}
</style>