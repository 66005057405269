<template>
  <footer class="footer bg-dark py-5 position-relative z-3">
    <div class="container pt-md-2 pt-lg-3 pt-xl-4">
      <div class="row pb-5 pt-sm-2 mb-lg-2">
        <div class="col-md-4 col-lg-3 pb-2 pb-md-0 mb-4 mb-md-0">
          <router-link class="navbar-brand py-0 mb-3 mb-md-4" to="/">
              <span class="text-primary flex-shrink-0 me-2">
               <img class="m-0" :src="require(`@/assets/images/logo.png`)" alt="" width="50">
              </span>
          </router-link>
          <p class="text-secondary fs-sm pb-2 pb-md-3 mb-3 text-justify">
            شرکت دانش بنیان ترسیم داده افزار، باهدف توسعه اقتصاد دانش محور و تجاری سازی نتایج حاصل از تحقیق و توسعه در
            حوزه فناوری اطلاعات با تولید محصولات نرم افزاری، تشکیل شد. ترسیم داده افزار، دارای تجربیات متعدد در زمینه
            طراحی، تولید و استقرار سامانه های یکپارچه در مقیاس کلان در کل کشور می باشد.
          </p>
          <div class="d-flex gap-3">
            <a class="btn btn-icon btn-sm btn-danger rounded-circle" href="https://www.aparat.com/tarsimdade"
               target="_blank" aria-label="Aparat">
              <img :src="require(`@/assets/images/contact/aparat.png`)" width="20"
                   alt="" style="filter: brightness(100);">
            </a>
            <a class="btn btn-icon btn-sm btn-primary bg-gradient-primary rounded-circle"
               href="https://instagram.com/tarsimdade?utm_medium=copy_link" target="_blank" aria-label="Instagram">
              <i class="fab fa-instagram"></i>
            </a>
            <a class="btn btn-icon btn-sm btn-primary rounded-circle"
               href="https://www.linkedin.com/company/tarsim-dade-afzar" target="_blank" aria-label="LinkedIn">
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
        <div class="col-md-8 col-lg-7 col-xl-6 offset-lg-2 offset-xl-3">
          <div class="row row-cols-1 row-cols-sm-3">
            <div class="col mb-4 mb-md-0">
              <h4 class="h6 fw-bold pb-lg-1">ترسیم داده افزار</h4>
              <ul class="nav flex-column">
                <li>
                  <router-link class="nav-link fw-normal text-secondary py-1 px-0" to="/about">درباره ما</router-link>
                </li>
                <li><a class="nav-link fw-normal text-secondary py-1 px-0" href="#">ویژگی ها</a></li>
                <li><a class="nav-link fw-normal text-secondary py-1 px-0" href="#">نحوه کار</a></li>
                <li><a class="nav-link fw-normal text-secondary py-1 px-0" href="#">بازخورد ها</a></li>
              </ul>
            </div>
            <div class="col mb-4 mb-md-0">
              <h4 class="h6 fw-bold pb-lg-1">پشتیبانی</h4>
              <ul class="nav flex-column">
                <li>
                  <router-link class="nav-link text-secondary fw-normal py-1 px-0" to="/contact">تماس با ما
                  </router-link>
                </li>
                <li><a class="nav-link text-secondary fw-normal py-1 px-0" href="#">شرایط خدمات</a></li>
                <li><a class="nav-link text-secondary fw-normal py-1 px-0" href="#">قوانین و مقررات</a></li>
              </ul>
            </div>
            <div class="col">
              <h4 class="h6 fw-bold pb-2 mb-0 mb-lg-1">آدرس</h4>
              <p class="text-secondary text-justify">
                تهران، خیابان نلسون ماندلا، خیابان تابان غربی، پلاک 8
              </p>
              <h4 class="h6 fw-bold pb-2 mb-0 mb-lg-1 mt-4">تلفن</h4>
              <p class="text-secondary text-justify">
                <a href="tel:+982188781506" class="d-block max-content" style="direction: ltr;">021 8878 15 06</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <p class="nav fs-sm mb-0">
        <span class="text-secondary">© تمامی حقوق محفوظ است. ساخته شده توسط</span>
        <span class="fw-normal p-0 ms-1" style="color: var(--color-2)">
          شرکت دانش بنیان ترسیم داده افزار
        </span>
        .
      </p>
    </div>
  </footer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.footer {
  background-image: url("@/assets/images/home/hero-bg2.webp");
  background-size: cover;
  background-position: top;
}

.btn-icon {
  --ar-btn-size: 3rem;
  flex-shrink: 0;
  width: var(--ar-btn-size);
  height: var(--ar-btn-size);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

@media only screen and (max-width: 575px) {
  .footer {
    background: #121519 !important;
    text-align: center;

    .col p {
      text-align: center !important;
    }
    .col a {
      text-align: center !important;
      margin: auto;
    }
  }
}
</style>