<template>
  <div class="mt-5 pt-5 pb-5 mb-5">
    <div class="mx-2">
      <h4 class="text-start mb-0 ms-2">عکس های منتخب</h4>
      <swiper
          dir="rtl" :grabCursor="true" :loop="true" :effect="'coverflow'"
          :coverflowEffect="{rotate: 50,stretch: 0,depth: 100, modifier: 1,slideShadows: true, }"
          :slidesPerView="3" :spaceBetween="30" :pagination="{ clickable: true,}"  :breakpoints="breakpoints"
          :modules="modules" :autoplay="{delay: 0,pauseOnMouseEnter: true,disableOnInteraction: false}"
          :speed="9000" class="gallery-swiper">
        <swiper-slide v-for="(item,index) in images" :key="index">
          <img :src="require(`@/assets/images/gallery/${item.src}`)" alt="">
        </swiper-slide>
      </swiper>
    </div>
    <div class="mx-2 mt-5">
      <h4 class="text-start mb-3 ms-2">همایش هفتمین دوره جایزه ملی تعالی نگهداشت و مدیریت دارایی های فیزیکی ایران</h4>
      <swiper
          dir="rtl" :grabCursor="true" :loop="true" :pagination="{type: 'fraction',}"
          :slidesPerView="3" :spaceBetween="20" :navigation="true"
          :modules="modules"  :breakpoints="breakpoints"
         class="gallery-swiper pt-0">
        <swiper-slide v-for="(item,index) in eam1403" :key="index">
          <img :src="require(`@/assets/images/gallery/hamayeshEAM1403/${item.src}`)" alt="">
        </swiper-slide>
      </swiper>
    </div>
    <div class="mx-2 mt-5">
      <h4 class="text-start mb-0 ms-2">دومین جشن سال روز دانش بنیان</h4>
      <swiper
          dir="rtl" :grabCursor="true" :loop="true" :pagination="{type: 'fraction',}"
          :slidesPerView="3" :spaceBetween="20" :navigation="true"
          :modules="modules"  :breakpoints="breakpoints"
         class="gallery-swiper pt-0">
        <swiper-slide v-for="(item,index) in DaneshBonian1402" :key="index">
          <img :src="require(`@/assets/images/gallery/DaneshBonian1402/${item.src}`)" alt="">
        </swiper-slide>
      </swiper>
    </div>
    <div class="mx-2 mt-5">
      <h4 class="text-start mb-0 ms-2">اولین سال روز دانش بنیان</h4>
      <swiper
          dir="rtl" :grabCursor="true" :loop="true" :pagination="{type: 'fraction',}"
          :slidesPerView="3" :spaceBetween="20" :navigation="true"
          :modules="modules"  :breakpoints="breakpoints"
         class="gallery-swiper pt-0">
        <swiper-slide v-for="(item,index) in DaneshBonian1401" :key="index">
          <img :src="require(`@/assets/images/gallery/DaneshBonian1401/${item.src}`)" alt="">
        </swiper-slide>
      </swiper>
    </div>
    <div class="mx-2 mt-5">
      <h4 class="text-start mb-0 ms-2">عکس های متفرقه</h4>
      <swiper
          dir="rtl" :grabCursor="true" :loop="true" :pagination="{type: 'fraction',}"
          :slidesPerView="3" :spaceBetween="20" :navigation="true"
          :modules="modules"  :breakpoints="breakpoints"
         class="gallery-swiper pt-0">
        <swiper-slide v-for="(item,index) in others" :key="index">
          <img :src="require(`@/assets/images/gallery/others/${item.src}`)" alt="">
        </swiper-slide>
      </swiper>
    </div>
    <div class="mx-2 mt-5">
      <h4 class="text-start mb-0 ms-2">هشتمین نمایشگاه بین المللی HSE</h4>
      <swiper
          dir="rtl" :grabCursor="true" :loop="true" :pagination="{type: 'fraction',}"
          :slidesPerView="3" :spaceBetween="20" :navigation="true"
          :modules="modules"  :breakpoints="breakpoints"
         class="gallery-swiper pt-0">
        <swiper-slide v-for="(item,index) in hse1402" :key="index">
          <img :src="require(`@/assets/images/gallery/hse1402/${item.src}`)" alt="">
        </swiper-slide>
      </swiper>
    </div>
    <div class="mx-2 mt-5">
      <h4 class="text-start mb-4 ms-2">همایش تخصصی نگهداری و تعمیرات در صنایع فولاد و معدنی</h4>
      <swiper
          dir="rtl" :grabCursor="true" :loop="true" :pagination="{type: 'fraction',}"
          :slidesPerView="3" :spaceBetween="20" :navigation="true"
          :modules="modules"  :breakpoints="breakpoints"
         class="gallery-swiper pt-0">
        <swiper-slide v-for="(item,index) in foolad1402" :key="index">
          <img :src="require(`@/assets/images/gallery/foolad/${item.src}`)" alt="">
        </swiper-slide>
      </swiper>
    </div>
    <div class="mx-2 mt-5">
      <h4 class="text-start mb-0 ms-2">همایش ششمین دوره جایزه ملی تعالی نگهداری و مدیریت دارایی ایران</h4>
      <swiper
          dir="rtl" :grabCursor="true" :loop="true" :pagination="{type: 'fraction',}"
          :slidesPerView="3" :spaceBetween="20" :navigation="true"
          :modules="modules"  :breakpoints="breakpoints"
         class="gallery-swiper pt-0">
        <swiper-slide v-for="(item,index) in jayezeMeli1402" :key="index">
          <img :src="require(`@/assets/images/gallery/jayezeMeli1402/${item.src}`)" alt="">
        </swiper-slide>
      </swiper>
    </div>
    <div class="mx-2 mt-5">
      <h4 class="text-start mb-3 ms-2">دومین سمینار ملی آموزش تخصصی پایش وضعیت و عیب یابی تجهیزات</h4>
      <swiper
          dir="rtl" :grabCursor="true" :loop="true" :pagination="{type: 'fraction',}"
          :slidesPerView="3" :spaceBetween="20" :navigation="true"
          :modules="modules"  :breakpoints="breakpoints"
         class="gallery-swiper pt-0">
        <swiper-slide v-for="(item,index) in seminarPayesh1402" :key="index">
          <img :src="require(`@/assets/images/gallery/seminarPayesh1402/${item.src}`)" alt="">
        </swiper-slide>
      </swiper>
    </div>
    <div class="mx-2 mt-5">
      <h4 class="text-start mb-3 ms-2">سی امین اجلاس مدیران نگهداری و تعمیرات صنعت پتروشیمی ایران</h4>
      <swiper
          dir="rtl" :grabCursor="true" :loop="true" :pagination="{type: 'fraction',}"
          :slidesPerView="3" :spaceBetween="20" :navigation="true"
          :modules="modules"  :breakpoints="breakpoints"
         class="gallery-swiper pt-0">
        <swiper-slide v-for="(item,index) in ejlasModiran1401" :key="index">
          <img :src="require(`@/assets/images/gallery/ejlasModiran1401/${item.src}`)" alt="">
        </swiper-slide>
      </swiper>
    </div>
    <div class="mx-2 mt-5">
      <h4 class="text-start mb-0 ms-2">همایش پنجمین دوره جایزه ملی تعالی نگهداری و مدیریت دارایی ایران</h4>
      <swiper
          dir="rtl" :grabCursor="true" :loop="true" :pagination="{type: 'fraction',}"
          :slidesPerView="3" :spaceBetween="20" :navigation="true"
          :modules="modules"  :breakpoints="breakpoints"
         class="gallery-swiper pt-0">
        <swiper-slide v-for="(item,index) in jayezeMeli1401" :key="index">
          <img :src="require(`@/assets/images/gallery/jayezeMeli1401/${item.src}`)" alt="">
        </swiper-slide>
      </swiper>
    </div>
    <div class="mx-2 mt-5">
      <h4 class="text-start mb-3 ms-2">بیست و نه امین اجلاس مدیران نگهداری و تعمیرات صنعت پتروشیمی ایران</h4>
      <swiper
          dir="rtl" :grabCursor="true" :loop="true" :pagination="{type: 'fraction',}"
          :slidesPerView="3" :spaceBetween="20" :navigation="true"
          :modules="modules"  :breakpoints="breakpoints"
         class="gallery-swiper pt-0">
        <swiper-slide v-for="(item,index) in elmoSanat1401" :key="index">
          <img :src="require(`@/assets/images/gallery/elmoSanat1401/${item.src}`)" alt="">
        </swiper-slide>
      </swiper>
    </div>
    <div class="mx-2 mt-5">
      <h4 class="text-start mb-0 ms-2">اولین سمینار ملی آموزش تخصصی پایش وضعیت و عیب یابی تجهیزات</h4>
      <swiper
          dir="rtl" :grabCursor="true" :loop="true" :pagination="{type: 'fraction',}"
          :slidesPerView="3" :spaceBetween="20" :navigation="true"
          :modules="modules"  :breakpoints="breakpoints"
         class="gallery-swiper pt-0">
        <swiper-slide v-for="(item,index) in seminarPayesh1401" :key="index">
          <img :src="require(`@/assets/images/gallery/seminarPayesh1401/${item.src}`)" alt="">
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {EffectCoverflow, Autoplay, Pagination,Navigation} from 'swiper/modules';

export default {
  components: {
    Swiper,
    SwiperSlide,
    Navigation,
  },
  data() {
    return {
      breakpoints: {
        '320': {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        '640': {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        '768': {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        '1600': {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
      images: [
        {src: 'g1.webp'},
        {src: 'g2.webp'},
        {src: 'g3.webp'},
        {src: 'g4.webp'},
        {src: 'g5.webp'},
        {src: 'g6.webp'},
        {src: 'g7.webp'},
        {src: 'g8.webp'},
        {src: 'g9.webp'},
        {src: 'g10.webp'},
        {src: 'g11.webp'},
        {src: 'g12.webp'},
        {src: 'g13.webp'},
        {src: 'g14.webp'},
        {src: 'g15.webp'},
      ],
      jayezeMeli1402: [
        {src: '1.webp'},
        {src: '3.webp'},
        {src: '4.webp'},
        {src: '2.webp'},
        {src: '5.webp'},
        {src: '6.webp'},
        {src: '7.webp'},
        {src: '8.webp'},
        {src: '9.webp'},
        {src: '10.webp'},
        {src: '11.webp'},
        {src: '12.webp'},
        {src: '13.webp'},
        {src: '14.webp'},
        {src: '15.webp'},
      ],
      eam1403: [
        {src: '1.webp'},
        {src: '3.webp'},
        {src: '33.webp'},
        {src: '4.webp'},
        {src: '2.webp'},
        {src: '6.webp'},
        {src: '7.webp'},
        {src: '8.webp'},
        {src: '9.webp'},
        {src: '10.webp'},
        {src: '11.webp'},
        {src: '12.webp'},
        {src: '13.webp'},
        {src: '14.webp'},
        {src: '15.webp'},
        {src: '16.webp'},
        {src: '17.webp'},
        {src: '18.webp'},
        {src: '19.webp'},
        {src: '20.webp'},
        {src: '21.webp'},
        {src: '22.webp'},
        {src: '23.webp'},
        {src: '24.webp'},
        {src: '25.webp'},
        {src: '26.webp'},
        {src: '27.webp'},
        {src: '28.webp'},
        {src: '29.webp'},
        {src: '30.webp'},
        {src: '31.webp'},
        {src: '32.webp'},
      ],
      seminarPayesh1402: [
        {src: '3.webp'},
        {src: '1.webp'},
        {src: '2.webp'},
        {src: '4.webp'},
        {src: '5.webp'},
        {src: '6.webp'},
        {src: '7.webp'},
        {src: '8.webp'},
        {src: '9.webp'},
        {src: '10.webp'},
      ],
      ejlasModiran1401: [
        {src: '1.webp'},
        {src: '2.webp'},
        {src: '3.webp'},
        {src: '4.webp'},
        {src: '5.webp'},
        {src: '6.webp'},
        {src: '7.webp'},
        {src: '8.webp'},
        {src: '9.webp'},
      ],
      jayezeMeli1401: [
        {src: '1.webp'},
        {src: '2.webp'},
        {src: '3.webp'},
        {src: '4.webp'},
        {src: '5.webp'},
        {src: '6.webp'},
        {src: '7.webp'},
        {src: '8.webp'},
      ],
      elmoSanat1401: [
        {src: '1.webp'},
        {src: '2.webp'},
        {src: '3.webp'},
        {src: '4.webp'},
        {src: '5.webp'},
        {src: '6.webp'},
        {src: '7.webp'},
        {src: '8.webp'},
      ],
      seminarPayesh1401: [
        {src: '1.webp'},
        // {src: '2.webp'},
        {src: '3.webp'},
        {src: '4.webp'},
        {src: '5.webp'},
        {src: '6.webp'},
        {src: '7.webp'},
      ],
      DaneshBonian1402: [
        {src: '1.webp'},
        {src: '6.webp'},
        {src: '3.webp'},
        {src: '10.webp'},
        {src: '5.webp'},
        {src: '2.webp'},
        {src: '7.webp'},
        {src: '8.webp'},
        {src: '9.webp'},
        {src: '4.webp'},
        {src: '11.webp'},
        {src: '12.webp'},
        {src: '13.webp'},
      ],
      DaneshBonian1401: [
        {src: '6.webp'},
        {src: '1.webp'},
        {src: '3.webp'},
        {src: '2.webp'},
        {src: '5.webp'},
        {src: '4.webp'},
      ],
      others: [
        {src: '1.webp'},
        {src: '5.webp'},
        {src: '3.webp'},
        {src: '2.webp'},
        {src: '6.webp'},
        {src: '4.webp'},
        {src: '7.webp'},
        {src: '8.webp'},
        {src: '9.webp'},
        {src: '10.webp'},
      ],
      hse1402: [
        {src: '1.webp'},
        {src: '8.webp'},
        {src: '3.webp'},
        {src: '2.webp'},
        {src: '6.webp'},
        {src: '4.webp'},
        {src: '7.webp'},
        {src: '5.webp'},
      ],
      foolad1402: [
        {src: '1.webp'},
        {src: '2.webp'},
        {src: '3.webp'},
        {src: '6.webp'},
        {src: '4.webp'},
        {src: '7.webp'},
        {src: '5.webp'},
      ],
    }
  },
  setup() {
    return {
      modules: [EffectCoverflow, Pagination, Autoplay,Navigation],
    };
  },
  mounted() {

  },
}
</script>

<style lang="scss" scoped>
.swiper {
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: .5rem;
  overflow: hidden;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 610px;
  object-fit: contain;
}


</style>
