<template>
<div>
  <Header/>
  <TarsimSystemMain/>
</div>
</template>

<script>
import Header from "@/components/Header";
import TarsimSystemMain from "@/components/TarsimSystemMain";
export default {
  components: {TarsimSystemMain, Header}
}
</script>

<style scoped>

</style>