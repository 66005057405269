<template>
  <div class="tarsimate">
    <section class="position-relative pt-lg-3 pt-xl-4 pt-xxl-5">
      <div class="bg-primary position-absolute start-0 bottom-0 w-100 d-none d-xl-block" style="height: 920px;"></div>
      <div class="bg-primary position-absolute start-0 bottom-0 w-100 d-none d-lg-block d-xl-none"
           style="height: 830px;"></div>
      <div class="bg-primary position-absolute start-0 bottom-0 w-100 d-lg-none d-xl-none" style="height: 62%;"></div>
      <div class="bg-opacity-10 rounded-circle position-absolute start-50 d-none d-lg-block"
           style="bottom: 220px; width: 480px; height: 480px; margin-left: -240px;"></div>
      <div class="container position-relative z-5 mt-2 pt-5 pb-2 pb-sm-4 pb-lg-5">
        <h1 class="display-3 text-center mx-auto pt-5 my-2 my-sm-1 main-title" style="max-width: 680px;">Tarsimate</h1>
        <h3 class="main-title text-center fw-normal my-2 my-sm-3">سامانه یکپارچه دستیار</h3>
        <svg class="d-block mx-auto text-sub" width="511" height="40" viewBox="0 0 511 40" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M385.252 0.160149C313.41 0.614872 292.869 0.910678 253.008 2.06539C211.7 3.26203 182.137 4.46154 135.231 6.84429C124.358 7.39665 112.714 7.92087 99.0649 8.47247C48.9242 10.4987 39.1671 11.0386 22.9579 12.6833C14.9267 13.4984 7.98117 14.0624 4.08839 14.2162C0.627411 14.3527 0 14.4386 0 14.7762C0 15.0745 5.53537 15.3358 8.56298 15.1804C9.64797 15.1248 12.5875 14.9887 15.0956 14.8782C17.6037 14.7676 23.123 14.4706 27.3608 14.2183C37.3399 13.6238 42.1312 13.4363 59.2817 12.9693C88.1133 12.1844 109.893 11.43 136.647 10.2898C146.506 9.86957 159.597 9.31166 165.737 9.04993C212.308 7.06466 269.195 5.29439 303.956 4.74892C346.139 4.08665 477.094 3.50116 474.882 3.98441C474.006 4.17607 459.021 5.6015 450.037 6.34782C441.786 7.03345 428 8.02235 411.041 9.14508C402.997 9.67773 391.959 10.4149 386.51 10.7832C366.042 12.1673 359.3 12.5966 347.67 13.2569C294.096 16.2987 258.708 18.9493 209.451 23.6091C180.174 26.3788 156.177 29.5584 129.396 34.2165C114.171 36.8648 112.687 37.3352 114.186 39.0402C115.161 40.1495 122.843 40.2933 138.338 39.492C166.655 38.0279 193.265 36.8923 219.043 36.048C235.213 35.5184 237.354 35.4296 253.795 34.6075C259.935 34.3005 270.549 33.8517 277.382 33.6105L289.804 33.1719L273.293 32.999C248.274 32.7369 221.435 32.7528 212.596 33.035C183.334 33.9693 167.417 34.6884 141.419 36.2506C135.222 36.623 129.994 36.8956 129.801 36.8566C127.94 36.4786 169.612 30.768 189.492 28.6769C234.369 23.956 280.582 20.4337 351.602 16.3207C358.088 15.9451 371.108 15.1233 380.535 14.4947C389.962 13.866 404.821 12.8761 413.556 12.2946C447.177 10.057 457.194 9.22358 489.506 5.97543C510.201 3.895 510.311 3.8772 510.875 2.50901C511.496 1.00469 509.838 0.322131 505.088 0.127031C500.576 -0.0584957 416.098 -0.0351424 385.252 0.160149ZM291.144 33.02C291.536 33.0658 292.102 33.0641 292.402 33.0162C292.703 32.9683 292.383 32.9308 291.691 32.9329C290.999 32.935 290.753 32.9743 291.144 33.02Z"></path>
        </svg>
        <div class="row justify-content-center pt-3 pt-sm-4 pt-md-5 mt-sm-1">
          <div class="col-8 col-lg-4 order-lg-2" style="margin-top: -105px;">
            <div class="position-lg-sticky top-0 mb-5 pb-sm-2 pb-xl-4" style="padding-top: 110px;">
              <img class="d-block mx-auto" :src="require(`@/assets/images/tarsimate/screen02.webp`)" width="322"
                   alt="App screen">
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 order-lg-1 overflow-hidden mt-lg-4 pt-xl-3">
            <img class="d-none d-lg-block rounded-4 ms-auto"
                 :src="require(`@/assets/images/tarsimate/issue.webp`)" width="297" alt="App screen"
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 ms-auto mt-5"
                 :src="require(`@/assets/images/tarsimate/assessment.webp`)" width="297" alt="App screen"
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 ms-auto mt-5"
                 :src="require(`@/assets/images/tarsimate/crm.webp`)" width="297" alt="App screen"
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 ms-auto mt-5"
                 :src="require(`@/assets/images/tarsimate/dashboard.webp`)" width="297" alt="App screen"
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 ms-auto mt-5"
                 :src="require(`@/assets/images/tarsimate/kms.webp`)" width="297" alt="App screen"
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 ms-auto mt-5"
                 :src="require(`@/assets/images/tarsimate/meetings.webp`)" width="297" alt="App screen"
                 style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <div class="d-none d-xxl-block" style="height: 310px;"></div>
            <div class="d-none d-xl-block d-xxl-none" style="height: 280px;"></div>
            <div class="d-none d-lg-block d-xl-none" style="height: 200px;"></div>
            <div class="text-center text-sm-start mb-5 mx-auto mx-sm-0 pb-lg-2 pb-xl-4"
                 style="max-width: 340px;" data-disable-parallax-down="lg">
              <div class="d-table bg-opacity-10 rounded-1 p-2 mx-auto mx-sm-0 mb-3 mb-lg-4">
                <svg class="d-block m-1 text-warning" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M12.0005 7.99608C11.897 7.99608 11.795 7.97508 11.699 7.93308L5.4485 5.18358C5.177 5.06358 5 4.79508 5 4.49658C5 4.19808 5.1755 3.92958 5.4485 3.80958L11.699 1.06007C11.891 0.97607 12.1115 0.97607 12.3035 1.06007L18.554 3.80958C18.8255 3.92958 19.0025 4.19808 19.0025 4.49658C19.0025 4.79508 18.827 5.06358 18.554 5.18358L12.3035 7.93308C12.206 7.97508 12.104 7.99608 12.0005 7.99608Z"></path>
                  <path
                      d="M12.907 9.3054C12.616 9.4329 12.313 9.4959 12.001 9.4959C11.689 9.4959 11.386 9.43291 11.098 9.30691L7 7.50391V8.19241C7 8.80291 7.3195 9.3759 7.831 9.6879C9.2725 10.5624 10.636 10.9989 11.9995 10.9989C13.363 10.9989 14.7265 10.5609 16.168 9.6879C16.681 9.3759 16.999 8.80291 16.999 8.19241V7.50391L12.907 9.3054Z"></path>
                  <path
                      d="M22.2495 4.99909H20.949C20.79 5.87959 20.2095 6.62959 19.38 7.00009H22.0005V19.0001H1.9995V7.00009H4.62C3.7905 6.62959 3.21 5.87959 3.0495 5.00059H1.7505C0.7905 4.99909 0 5.78959 0 6.74959V19.2491C0 20.2091 0.7905 20.9996 1.7505 20.9996H10.0005V22.9991H8.001C7.4505 22.9991 7.0005 23.4491 7.0005 23.9996C7.0005 24.5501 7.4505 25.0001 8.001 25.0001H16.0005C16.551 25.0001 17.001 24.5501 17.001 23.9996C17.001 23.4491 16.551 22.9991 16.0005 22.9991H14.001V20.9996H22.251C23.211 20.9996 24.0015 20.2091 24.0015 19.2491V6.74959C24 5.78959 23.2095 4.99909 22.2495 4.99909Z"></path>
                </svg>
              </div>
              <h2 class="h4 text-white mb-2 mb-lg-3">دسترسی با همه دستگاه ها</h2>
              <p class="text-white opacity-80 mb-0">

              </p>
            </div>
            <div class="text-center text-sm-start mb-5 mx-auto mx-sm-0 pb-lg-2 pb-xl-4 "
                 style="max-width: 340px;"
                 data-disable-parallax-down="lg">
              <div class="d-table bg-opacity-10 rounded-1 p-2 mx-auto mx-sm-0 mb-3 mb-lg-4">
                <svg class="d-block m-1 text-warning" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M17.4995 10.9995C13.916 10.9995 11 13.9155 11 17.499C11 21.0825 13.9145 24 17.4995 24C21.0845 24 23.999 21.084 23.999 17.5005C23.999 13.917 21.083 10.9995 17.4995 10.9995ZM21.803 15H19.9985V13.1955C20.7485 13.632 21.3665 14.2515 21.803 15ZM22.499 17.5005C22.499 17.8425 22.4645 18.177 22.3985 18.501H20V16.5H22.3985C22.4645 16.824 22.499 17.157 22.499 17.5005ZM12.4985 17.5005C12.4985 17.1585 12.533 16.824 12.599 16.5H14.999V18.4995H12.6005C12.5345 18.177 12.4985 17.8425 12.4985 17.5005ZM16.499 16.5H18.4985V18.4995H16.499V16.5ZM18.4985 12.6015V15H16.499V12.6015C16.8215 12.5355 17.156 12.501 17.4995 12.501C17.843 12.501 18.176 12.5355 18.4985 12.6015ZM14.999 13.1955V15H13.1945C13.631 14.2515 14.2505 13.632 14.999 13.1955ZM13.1945 19.9995H14.999V21.804C14.2505 21.3675 13.631 20.7495 13.1945 19.9995ZM16.499 22.3995V20.001H18.4985V22.3995C18.176 22.4655 17.8415 22.5 17.4995 22.5C17.1575 22.5 16.823 22.4655 16.499 22.3995ZM19.9985 21.804V19.9995H21.803C21.3665 20.7495 20.7485 21.3675 19.9985 21.804Z"></path>
                  <path
                      d="M16.0005 4.0005H15V1.0005C15 0.45 14.55 0 13.9995 0H3C2.1795 0 1.4205 0.3405 0.8805 0.8805C0.3405 1.4205 0 2.1705 0 3V19.0005C0 20.6505 1.35 22.0005 3 22.0005H10.29C9.4695 20.7 9 19.1505 9 17.5005C9 12.981 12.54 9.27 16.9995 9.0105V5.001C16.9995 4.4505 16.5495 4.0005 16.0005 4.0005ZM13.0005 4.0005H3C2.4495 4.0005 1.9995 3.5505 1.9995 3C1.9995 2.4495 2.4495 1.9995 3 1.9995H13.0005V4.0005Z"></path>
                </svg>
              </div>
              <h2 class="h4 text-white mb-2 mb-lg-3">کتابخانه آنلاین بزرگ</h2>
              <p class="text-white opacity-80 mb-0">

              </p>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 order-lg-3 overflow-hidden mt-lg-4 pt-xl-3">
            <img class="d-none d-lg-block rounded-4" :src="require(`@/assets/images/tarsimate/dms.webp`)"
                 width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 mt-5"
                 :src="require(`@/assets/images/tarsimate/project-management.webp`)"
                 width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 mt-5" :src="require(`@/assets/images/tarsimate/social.webp`)"
                 width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 mt-5" :src="require(`@/assets/images/tarsimate/wallet.webp`)"
                 width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 mt-5" :src="require(`@/assets/images/tarsimate/work-calendar.webp`)"
                 width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <img class="d-none d-lg-block rounded-4 mt-5" :src="require(`@/assets/images/tarsimate/workflow.webp`)"
                 width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
            <div class="d-none d-xxl-block" style="height: 520px;"></div>
            <div class="d-none d-xl-block d-xxl-none" style="height: 490px;"></div>
            <div class="d-none d-lg-block d-xl-none" style="height: 400px;"></div>
            <div class="text-center text-sm-start mb-5 mx-auto me-sm-0 pb-lg-2 pb-xl-4"
                 style="max-width: 340px;"
                 data-disable-parallax-down="lg">
              <div class="d-table bg-opacity-10 rounded-1 p-2 mx-auto mx-sm-0 mb-3 mb-lg-4">
                <svg class="d-block m-1 text-warning" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M11.9986 0C5.38318 0 0 5.38318 0 11.9986C0 18.6141 5.38041 24 11.9986 24C18.6168 24 23.9972 18.6168 23.9972 12.0014C23.9972 5.38595 18.6141 0 11.9986 0ZM16.7837 17.0052C16.5095 17.3541 16.1025 17.5396 15.6899 17.5396C15.3936 17.5396 15.0945 17.4427 14.8397 17.2489L10.6861 14.0173C10.3482 13.7542 10.1516 13.3499 10.1516 12.9235V6.92281C10.1516 6.15853 10.7719 5.53825 11.5362 5.53825C12.3005 5.53825 12.9207 6.15853 12.9207 6.92281V12.2451L16.54 15.0613C17.1437 15.5292 17.2517 16.4015 16.7837 17.0052Z"></path>
                </svg>
              </div>
              <h2 class="h4 text-white mb-2 mb-lg-3">دسترسی آسان و سریع</h2>
              <p class="text-white opacity-80 mb-0">

               </p>
            </div>
            <div class="text-center text-sm-start mb-5 mx-auto me-sm-0 pb-lg-2 pb-xl-4 "
                 style="max-width: 340px;" data-disable-parallax-down="lg">
              <div class="d-table  bg-opacity-10 rounded-1 p-2 mx-auto mx-sm-0 mb-3 mb-lg-4">
                <svg class="d-block m-1 text-warning" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M19.9524 20.9857C17.8306 22.8676 15.057 24 12.0132 24C5.38642 24 0 18.6128 0 11.985C0 5.60651 4.98548 0.379975 11.2624 0V5.02678C7.74852 5.39775 5.005 8.38048 5.005 11.985C5.005 15.8493 8.14795 18.9942 12.0132 18.9942C13.6755 18.9942 15.2072 18.413 16.4085 17.4428L19.9524 20.9857Z"></path>
                  <path
                      d="M24.0042 12.7358C23.8436 15.4797 22.7534 17.9818 21.0205 19.9253L17.4766 16.3808C18.298 15.3701 18.8386 14.1085 18.9782 12.7358H24.0042Z"></path>
                  <path
                      d="M23.998 11.234H18.9719C18.6311 7.97046 16.0287 5.36621 12.7656 5.02678V0C18.7917 0.360451 23.6376 5.20701 23.998 11.234Z"></path>
                </svg>
              </div>
              <h2 class="h4 text-white mb-2 mb-lg-3">گزارش گیری یکپارچه</h2>
              <p class="text-white opacity-80 mb-0">

              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="content-section">
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5" data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in issueTracker" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">مدیریت وظایف</h2>
                  <p class="pb-3 mb-lg-4">Issue Tracker</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimate/issue.webp`)"
                       width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5 bg-third">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in EDMS" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">مدیریت اسناد و مدارک</h2>
                  <p class="pb-3 mb-lg-4">EDMS</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimate/dms.webp`)"
                       width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in projectManagement" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">مدیریت پروژه</h2>
                  <p class="pb-3 mb-lg-4">Project Management</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimate/kms.webp`)"
                       width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5 bg-third">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in CRM" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">مدیریت ارتباط با مشتری</h2>
                  <p class="pb-3 mb-lg-4">CRM</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimate/crm.webp`)"
                       width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in wallet" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">کیف پول </h2>
                  <p class="pb-3 mb-lg-4">Wallet</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimate/wallet.webp`)"
                       width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5 bg-third">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in assessment" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">مدیریت ارزیابی</h2>
                  <p class="pb-3 mb-lg-4">Assessment</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimate/assessment.webp`)"
                       width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in KMS" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">مدیریت دانش</h2>
                  <p class="pb-3 mb-lg-4">KMS</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimate/kms.webp`)"
                       width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5 bg-third">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in social" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">شبکه اجتماعی</h2>
                  <p class="pb-3 mb-lg-4">Social</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimate/social.webp`)"
                       width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in meeting" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h3">مدیریت جلسات و مصوبات</h2>
                  <p class="pb-3 mb-lg-4">Meeting</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimate/meetings.webp`)"
                       width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5 bg-third">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in BPMS" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">موتور گردش کار</h2>
                  <p class="pb-3 mb-lg-4">BPMS</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimate/workflow.webp`)"
                       width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in BI" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">داشبور ساز و گزارش ساز</h2>
                  <p class="pb-3 mb-lg-4">BI</p>
                  <img class="d-block rounded-4" :src="require(`@/assets/images/tarsimate/dashboard.webp`)"
                       width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-lg-3 pt-xl-4 pt-xxl-5 pb-lg-3 pb-xl-4 pb-xxl-5 bg-third">
        <div class="container">
          <div class="row border border-light g-0">
            <div class="col-md-7 col-xl-8">
              <div class="card h-100 bg-dark border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5"
                   data-bs-theme="dark">
                <div class="position-absolute top-0 start-0 w-100 h-100 bg-noise"></div>
                <div class="card-body position-relative z-2 py-5 px-2" style="max-width: 732px;">
                  <ul class="list-unstyled mb-0">
                    <li class="pt-1 mt-2" v-for="(item, index) in calender" :key="index">
                      <i class="fas fa-check-circle text-sub fs-xl mt-1 me-2"></i>
                      {{ item.title }}
                      <p class="fs-7 text-secondary text-justify">{{ item.description }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-4 border-top border-md-0 border-start-md border-light">
              <div class="card h-100 bg-secondary border-0 rounded-0 py-md-3 py-lg-4 py-xl-5 px-3 px-sm-4 px-lg-5">
                <div class="card-body card-body-main py-5 px-2 position-lg-sticky  border-0">
                  <h2 class="h2">تقویم کاری</h2>
                  <p class="pb-3 mb-lg-4">Calender</p>
                  <img class="d-block rounded-4"
                       :src="require(`@/assets/images/tarsimate/work-calendar.webp`)"
                       width="297" alt="App screen" style="box-shadow: 0 .9375rem 3rem -.5rem rgba(18,34,50, .05);">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="aa-co position-fixed end-0 m-3">
      <img class="aa-el" alt="" style="width: 50px;" :src="require(`@/assets/images/home/astro.png`)"/>
    </div>
  </div>
</template>

<script>
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
  data() {
    return {
      issueTracker: [
        {
          title: 'مدیریت بورد و لیست',
          description: ''
        },
        {
          title: 'ثبت نامحدود وظایف',
          description: ''
        },
        {
          title: 'آرشیو وظایف',
          description: ''
        },
        {
          title: 'اولویت بندی وظایف',
          description: ''
        },
        {
          title: 'مدیریت اعضا',
          description: ''
        },
        {
          title: 'ثبت و مدیریت برچسب',
          description: ''
        },
        {
          title: 'افزودن انواع پیوست',
          description: ''
        },
        {
          title: 'ثبت چک لیست برای هر وظیفه',
          description: ''
        },
        {
          title: 'درج کامنت به صورت نامحدود',
          description: ''
        },
        {
          title: 'مشاهده روند پیشرفت وظیفه',
          description: ''
        },
        {
          title: 'ثبت تاریخ و ساعت انجام وظیفه',
          description: ''
        },
        {
          title: 'مدیریت سطوح دسترسی اعضا در هر بورد',
          description: ''
        },
        {
          title: 'مشاهده لیست وظایف من',
          description: ''
        },
        {
          title: 'مشاهده لیست وظایف اعضا',
          description: ''
        },
        {
          title: 'جست و جو پیشرفته',
          description: ''
        }
      ],
      EDMS: [
        {
          title: 'مدیریت انواع فایل پیوست',
          description: ''
        },
        {
          title: 'مدیریت Mime/ Type هر پیوست',
          description: ''
        },
        {
          title: 'تعریف محدودیت تعداد و حجم',
          description: ''
        },
        {
          title: 'مدیریت دسترسی اعضا',
          description: ''
        },
        {
          title: 'مدیریت رمزگذاری',
          description: ''
        },
        {
          title: 'مدیریت تغییرات نسخه',
          description: ''
        },
        {
          title: 'تعریف کدینگ',
          description: ''
        },
        {
          title: 'تعریف و مدیریت گردش اسناد',
          description: ''
        }
      ],
      projectManagement: [
        {
          title: 'تعریف نامحدود پروژه',
          description: ''
        },
        {
          title: 'تعریف WBS فعالیت ها',
          description: ''
        },
        {
          title: 'تعریف روابط بین فعالیت ها',
          description: ''
        },
        {
          title: 'وزن دهی به فعالیت ها',
          description: ''
        },
        {
          title: 'محاسبه درصد پیشرفت پروژه',
          description: ''
        },
        {
          title: 'نمایش گانت چارت پیشرفت پروژه',
          description: ''
        },
        {
          title: 'دریافت گزارش',
          description: ''
        },
        {
          title: 'تخصیص نامحدود منابع',
          description: ''
        },
        {
          title: 'نمایش تاخیرها',
          description: ''
        },
        {
          title: 'تعریف و مدیریت گردش فعالیت ها',
          description: ''
        },
        {
          title: 'تقویم کاری یکپارچه',
          description: ''
        }
      ],
      CRM: [
        {
          title: 'گروه بندی مشتریان',
          description: ''
        },
        {
          title: 'مدیریت پرونده مشتریان',
          description: ''
        },
        {
          title: 'مدیریت تیکتینگ',
          description: ''
        },
        {
          title: 'تعریف و مدیریت گردش تیکت ها',
          description: ''
        },
        {
          title: 'مشاهده و مدیریت روند پیگیری مشتری',
          description: ''
        },
        {
          title: 'دریافت گزارش',
          description: ''
        },
        {
          title: 'تقویم کاری یکپارچه',
          description: ''
        },
        {
          title: 'مدیریت و تعریف انواع فرم نظرسنجی',
          description: ''
        },
        {
          title: 'مدیریت بانک پیام',
          description: ''
        },
        {
          title: 'فراخوانی پیام / فرم در مراحل مختلف',
          description: ''
        },
        {
          title: 'مدیریت گارانتی / وارانتی',
          description: ''
        }
      ],
      wallet: [
        {
          title: 'مدیریت حساب ها',
          description: ''
        },
        {
          title: 'مدیریت انواع فرآیندهای مالی',
          description: ''
        },
        {
          title: 'تسهیم',
          description: ''
        },
        {
          title: 'مدیریت روش های تسویه',
          description: ''
        },
        {
          title: 'مدیریت درگاه',
          description: ''
        },
        {
          title: 'دریافت گزارش',
          description: ''
        },
        {
          title: 'تعریف و مدیریت فرآیندهای مالی',
          description: ''
        }
      ],
      assessment: [
        {
          title: 'تعریف انواع ارزیابی',
          description: ''
        },
        {
          title: 'ثبت و محاسبه شاخص های عملکردی',
          description: ''
        },
        {
          title: 'تعریف انواع سوال و جواب',
          description: ''
        },
        {
          title: 'تخصیص امتیاز به سوال و جواب',
          description: ''
        },
        {
          title: 'دریافت گزارش های عملکردی',
          description: ''
        }
      ],
      KMS: [
        {
          title: 'ایجاد درختواره دانش',
          description: ''
        },
        {
          title: 'نشر دانش',
          description: ''
        },
        {
          title: 'ثبت و مدیریت سوال',
          description: ''
        },
        {
          title: 'ارزیابی دانش',
          description: ''
        },
        {
          title: 'جست و جوی دانش',
          description: ''
        },
        {
          title: 'مدیریت دسترسی اعضا',
          description: ''
        },
        {
          title: 'مدیریت و تعریف گردش دانش',
          description: ''
        },
        {
          title: 'ارتباط یکپارچه با مدیریت اسناد',
          description: ''
        }
      ],
      social: [
        {
          title: 'ثبت و ارسال پست',
          description: ''
        },
        {
          title: 'درج نظر کاربران',
          description: ''
        },
        {
          title: 'رزومه ساز',
          description: ''
        },
        {
          title: 'ارسال پیام خصوصی',
          description: ''
        },
        {
          title: 'مدیریت دنبال کنندگان',
          description: ''
        },
        {
          title: 'ساخت رویداد',
          description: ''
        }
      ],
      meeting: [
        {
          title: 'مدیریت صورتجلسات',
          description: ''
        },
        {
          title: 'مدیریت دعوت',
          description: ''
        },
        {
          title: 'مدیریت نقش ها',
          description: ''
        },
        {
          title: 'ارتباط یکپارچه با مدیریت اسناد',
          description: ''
        },
        {
          title: 'مدیریت مصوبات',
          description: ''
        },
        {
          title: 'ارتباط یکپارچه با موتور گردش کار',
          description: ''
        }
      ],
      BPMS: [
        {
          title: 'محیط گرافیکی',
          description: ''
        },
        {
          title: 'مطابق با استاندارد BPMN2',
          description: ''
        },
        {
          title: 'ارتباط یکپارچه با سایر سیستم ها',
          description: ''
        },
        {
          title: 'مدیریت ارتباط فعالیت ها',
          description: ''
        },
        {
          title: 'مدیریت کلیدهای داینامیک',
          description: ''
        },
        {
          title: 'تعریف زیرفرآیند',
          description: ''
        }
      ],
      BI: [
        {
          title: 'ایجاد گزارشات متنوع',
          description: ''
        },
        {
          title: 'فراخوانی گزارشات در داشبورد',
          description: ''
        },
        {
          title: 'افزودن شروط',
          description: ''
        },
        {
          title: 'نمایش به صورت چارت',
          description: ''
        },
        {
          title: 'نماش Pivot',
          description: ''
        },
        {
          title: 'سطوح دسترسی داشبورد',
          description: ''
        }
      ],
      calender: [
        {
          title: 'مشاهده وظایف تخصیص داده شده',
          description: ''
        },
        {
          title: 'مشاهده سر رسید گارانتی/ وارانتی',
          description: ''
        },
        {
          title: 'جابه جایی فعالیت ها',
          description: ''
        },
        {
          title: 'حالت های نمایش مختلف',
          description: ''
        },
        {
          title: 'جست و جوی فعالیت ها',
          description: ''
        },
        {
          title: 'مدیریت هشدار',
          description: ''
        }
      ],
    }
  },
  mounted() {
    this.$watch(
        () => this.$route.name,
        () => {
          this.scrollAnimation2();
        },
        {immediate: true}
    )
  },
  methods: {
    scrollAnimation2() {
      gsap.timeline({
        scrollTrigger: {
          trigger: "html",
          scrub: 0.2,
          start: "top top",
          end: "+=10000"
        }
      })
          .to(".aa-el", {
            rotation: 360 * 5,
            duration: 1,
            ease: "none"
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.aa-co {
  animation: float linear 2.5s infinite alternate;
  bottom: 28px;
  z-index: 11;
}

@keyframes float {
  0% {
    transform: translateZ(0);
  }
  to {
    transform: translate3d(0, 30px, 0);
  }
}

.card-body-main {
  flex: unset;
  top: 64px;
}

.bg-primary {
  --td-primary-rgb: 68, 140, 116;
  --td-bg-opacity: 1;
  background-color: var(--color-2) !important;
}

.bg-third {
  background-color: #0c1015 !important;
}

.bg-opacity-10 {
  background-color: rgba(18, 21, 25, 0.10) !important;
}

.bg-dark {
  background-color: rgba(18, 21, 25, 1) !important;
}

.bg-noise {
  mix-blend-mode: soft-light;
  opacity: 12%;
  background-image: url("@/assets/images/tarsimate/noise.png");
}

.bg-secondary {
  background-color: rgb(32, 34, 36) !important;
  color: #ffffff;
}

.card-footer {
  padding: var(--ar-card-cap-padding-y) var(--ar-card-cap-padding-x);
  color: var(--ar-card-cap-color);
  background-color: transparent;
  border-top: var(--ar-card-border-width) solid var(--ar-card-border-color);
}

.border-light {
  border-color: rgba(255, 255, 255, .13) !important;
}

.main-title {
  font-family: Pelak, sans-serif;
}

img {
  margin: 0;
}

@media only screen and (min-width: 992px) {
  .position-lg-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}
@media only screen and (max-width: 991px) {
  .content-section{
    margin-top: 3rem;
  }
}
@media only screen and (max-width: 767px) {
  .content-section{
    .col-md-5 .card-body{
      .h2,p{
        text-align: center;
      }
      img{
        margin-right: auto;
        margin-left: auto;
      }
    }
   .col-md-7{
     order: 2;
   }
  }
}
</style>