import {createRouter, createWebHistory} from 'vue-router'
import {seoGuardWithNext} from '@aminoeditor/vue-router-seo';
import HomeView from '../views/HomeView.vue'
import AboutView from "@/views/AboutView";
import TarsimSystem from "@/views/TarsimSystem";
import Contact from "@/views/Contact";
import Gallery from "@/views/Gallery";
import TarsimMate from "@/views/TarsimMate";
import Xstone from "@/views/Xstone";
import ESB from "@/views/ESB";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView,
        meta: {
            seo: {
                title: 'ترسیم داده افزار',
            }
        }
    },
    {
        path: '/about',
        name: 'About',
        component: AboutView,
        alias: '/whyUs',
        meta: {
            seo: {
                title: 'ترسیم داده افزار | درباره ما',
            }
        }
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        meta: {
            seo: {
                title: 'ترسیم داده افزار | تماس با ما',
            }
        }
    },
    {
        path: '/gallery',
        name: 'Gallery',
        component: Gallery,
        meta: {
            seo: {
                title: 'ترسیم داده افزار | گالری تصاویر',
            }
        }
    },
    {
        path: '/tarsimSystem',
        name: 'TarsimSystem',
        component: TarsimSystem,
        meta: {
            seo: {
                title: 'ترسیم داده افزار | ترسیم سیستم',
            }
        }
    },
    {
        path: '/tarsimMate',
        name: 'TarsimMate',
        component: TarsimMate,
        meta: {
            seo: {
                title: 'ترسیم داده افزار | سامانه یکپارچه دستیار',
            }
        }
    },
    {
        path: '/xstone',
        name: 'Xstone',
        component: Xstone,
        meta: {
            seo: {
                title: 'ترسیم داده افزار | زیرساخت نرم افزاری Xstone',
            }
        }
    },
    {
        path: '/esb',
        name: 'ESB',
        component: ESB,
        meta: {
            seo: {
                title: 'ترسیم داده افزار | گذرگاه سرویس سازمانی ESB',
            }
        }
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: function (to, _from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return {el: to.hash, behavior: "smooth"};
        } else {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 100);
        }
    }
})
router.beforeEach(seoGuardWithNext);
router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    next();
});
export default router
