<template>
  <div>
    <Header/>
    <TarsimMateMain/>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import TarsimMateMain from "@/components/TarsimMateMain";
import Footer from "@/components/Footer";

export default {
  name: "TarsimMate",
  components: {Footer, TarsimMateMain, Header}
}
</script>

<style scoped>

</style>