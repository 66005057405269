<template>
  <div id="container" ref="container"></div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import * as THREE from 'three';

const container = ref(null);
let camera, scene, renderer;

onMounted(() => {
  init();
  animate();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onWindowResize);
});

function init() {
  container.value = document.getElementById('container');

  camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 15000);
  camera.position.z = 1000;

  scene = new THREE.Scene();
  scene.fog = new THREE.Fog(0x000000, 1, 15000);

  const pointLight = new THREE.PointLight(0x003bff, 3, 0, 0);
  pointLight.position.set(0, 0, 0);
  scene.add(pointLight);

  const dirLight = new THREE.DirectionalLight(0xffffff, 3);
  dirLight.position.set(0, 0, 1).normalize();
  scene.add(dirLight);

  const geometry = [
    [new THREE.IcosahedronGeometry(100, 16), 50],
    [new THREE.IcosahedronGeometry(100, 8), 300],
    [new THREE.IcosahedronGeometry(100, 4), 1000],
    [new THREE.IcosahedronGeometry(100, 2), 2000],
    [new THREE.IcosahedronGeometry(100, 1), 8000]
  ];

  const material = new THREE.MeshLambertMaterial({ color: 0xffffff, wireframe: true });

  for (let j = 0; j < 1000; j++) {
    const lod = new THREE.LOD();
    for (let i = 0; i < geometry.length; i++) {
      const mesh = new THREE.Mesh(geometry[i][0], material);
      mesh.scale.set(1.5, 1.5, 1.5);
      mesh.updateMatrix();
      mesh.matrixAutoUpdate = false;
      lod.addLevel(mesh, geometry[i][1]);
    }
    lod.position.x = 10000 * (0.5 - Math.random());
    lod.position.y = 13500 * (0.5 - Math.random());
    lod.position.z = 10000 * (0.5 - Math.random());
    lod.updateMatrix();
    lod.matrixAutoUpdate = false;
    scene.add(lod);
  }

  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  container.value.appendChild(renderer.domElement);

  window.addEventListener('resize', onWindowResize);
}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}

function animate() {
  requestAnimationFrame(animate);
  render();
}

function render() {
  camera.position.x += (window.scrollY - camera.position.x) * 0.036;
  camera.position.y += (-window.scrollY - camera.position.y) * 0.036;
  renderer.render(scene, camera);
}
</script>

<style scoped>
#container {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.2;
}
@media only screen and (max-width: 992px) {
  #container {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}
</style>